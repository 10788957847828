import React, { useState } from "react"
import { BsCardImage, BsFullscreen, BsList } from "react-icons/bs"
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap"
import { MdOutlineAddCircleOutline } from "react-icons/md"
import { BiCode, BiRotateRight } from "react-icons/bi"
import { FaFilePdf } from "react-icons/fa"
import { FiLink } from "react-icons/fi"
import ColorContainer from "./ColorContainer"
import { rgbToHex } from "../../helpers/ColorHelper"
import { getGradientString } from "./GradientBackgrounds"

function GradientHeader({
                          location,
                          copyToClipboard,
                          handleExportPdf,
                          handleDownloadGradient,
                          handleOpenCSSModal,
                          rotationDegree,
                          handleRotate,
                          setShowThirdColor,
                          showThirdColor,
                          showAllGradients,
                          handleShowAllGradients,
                          handleHideAllGradients,
                          extractColorNames,
                          gradient,
                          setGradient,
                          getRandomColor,
                          showRemoveBtn, setShowRemoveBtn, openFullScreen, getRandomGradient,
                          currentGradientColors,
                          setCurrentGradientColors, isImgLoading, isPdfLoading
                        }) {

  const [hoveredIndex, setHoveredIndex] = useState(null)

  function hovered(index) {
    setHoveredIndex(index)
  }

  function handleColorChange(e, index) {
    const colorValues = [...currentGradientColors]
    colorValues[index] = e.target.value
    setCurrentGradientColors(colorValues)
    setGradient(getGradientString(rotationDegree, ...colorValues))
  }

  function handleRemoveColor(value) {
    const colorValues = [...currentGradientColors]
    colorValues.splice(value, 1)
    setCurrentGradientColors(colorValues)
    setGradient(getGradientString(rotationDegree, ...colorValues))
    setShowThirdColor(false)
  }

  function handleAddColor() {
    const colorValues = [...currentGradientColors]
    const color3 = rgbToHex(getRandomColor())
    colorValues.push(color3)
    setCurrentGradientColors(colorValues)
    setGradient(getGradientString(rotationDegree, ...colorValues))
    setShowRemoveBtn(true)
  }

  const showToolTip = (content) => (
    <Tooltip id="start-zenMode-tooltip">
      {content}
    </Tooltip>
  )


  return (
    <div>
      {!showAllGradients &&
      <div className={"d-flex justify-content-center pb-2"}>
        <button className="d-block btn btn-primary btn-sm" style={{ textTransform: "capitalize" }}
                onClick={() => getRandomGradient()}>Generate Gradient
        </button>
      </div>
      }
      {!showAllGradients && <div className="container text-center py-2 d-none d-md-block " style={{ fontSize: 15 }}>Press the spacebar to generate color gradients!</div>}
      <div
        className="d-flex flex-md-row flex-column gap-md-0 gap-3 align-items-center justify-content-md-between align-items-md-center mx-md-5 my-2">
        <button className="d-md-none d-block btn btn-primary btn-sm" onClick={() => getRandomGradient()}>Generate gradient
        </button>
        {!showAllGradients ? (
          <button className="btn py-2 px-2 d-flex align-items-center gap-2" onClick={handleShowAllGradients}>
            <BsList size={28} />
            <p style={{ textTransform: "capitalize", fontSize: 16 }}>Show All Gradients</p>
          </button>
        ) : (
          <button className="btn py-2 px-2 d-flex align-items-center gap-2" onClick={handleHideAllGradients}>
            <BsList size={28} />
            <p style={{ textTransform: "capitalize", fontSize: 16 }}>Hide All Gradients</p>
          </button>
        )}
        {!showAllGradients && <>
          <div className="d-flex gap-2 align-items-center">
            <div className="d-flex gap-2 align-items-center flex-column flex-lg-row">
              {extractColorNames(gradient).map((colorValue, index) => (
                <ColorContainer
                  key={index}
                  colorValue={colorValue}
                  index={index}
                  hovered={hovered}
                  handleRemoveColor={handleRemoveColor}
                  totalColors={extractColorNames(gradient).length}
                  handleColorChange={handleColorChange}
                  removeBtnStyle={showRemoveBtn && hoveredIndex === index ? "remove-btn-show" : "remove-btn-hide"}
                  showToolTip={showToolTip}
                  showRemoveBtn={showRemoveBtn}
                />
              ))}
            </div>
          </div>
          <div className="d-flex align-items-center">
            <OverlayTrigger placement="bottom" overlay={showToolTip("Start Zen Mode")}>
              <Button className="px-2 mx-2 py-2 d-none d-lg-block" variant="light"
                      onClick={openFullScreen}>
                <BsFullscreen size={18} />
              </Button>
            </OverlayTrigger>
            {/* <hr className="d-none d-lg-block" style={{ width: "1.6rem", transform: "rotate(90deg)" }} /> */}
            <OverlayTrigger placement="bottom" overlay={showToolTip("Add Color")}>
              <Button className="px-2 mx-2 py-2" variant="light" style={{ color: showThirdColor ? "#00000040" : "" }}
                      disabled={showThirdColor}
                      onClick={() => handleAddColor()}>
                <MdOutlineAddCircleOutline size={24} />
              </Button>
            </OverlayTrigger>
            {/* <hr style={{ width: "1.6rem", transform: "rotate(90deg)" }} /> */}
            <OverlayTrigger placement="bottom" overlay={showToolTip("Rotate Color")}>
              <Button className="px-2 mx-2 py-2" variant="light" onClick={() => handleRotate(rotationDegree + 90)}>
                <BiRotateRight size={26} />
              </Button>
            </OverlayTrigger>
            {/* <hr style={{ width: "1.6rem", transform: "rotate(90deg)" }} /> */}
            <OverlayTrigger placement="bottom" overlay={showToolTip("Get CSS")}>
              <Button className="px-2 mx-2 py-2" variant="light" onClick={handleOpenCSSModal}>
                <BiCode size={26} />
              </Button>
            </OverlayTrigger>
            {/* <hr style={{ width: "1.6rem", transform: "rotate(90deg)" }} /> */}
            <OverlayTrigger placement="bottom" overlay={showToolTip("Export Image")}>
              <Button className="px-2 mx-2 py-2" variant="light" onClick={handleDownloadGradient}>
                {isImgLoading ? <div className="spinner-border spinner-border-sm" role="status" >
                  <span className="visually-hidden" >Loading...</span>
                </div> : <BsCardImage size={22} />}
              </Button>
            </OverlayTrigger>
            {/* <hr style={{ width: "1.6rem", transform: "rotate(90deg)" }} /> */}
            <OverlayTrigger placement="bottom" overlay={showToolTip("Export PDF")}>
              <Button className="px-2 mx-2 py-2" variant="light" onClick={handleExportPdf}>
                {isPdfLoading ? <div className="spinner-border spinner-border-sm" role="status" >
                  <span className="visually-hidden" >Loading...</span>
                </div> : <FaFilePdf size={22} />}

              </Button>
            </OverlayTrigger>
            {/* <hr style={{ width: "1.6rem", transform: "rotate(90deg)" }} /> */}
            <OverlayTrigger placement="bottom" overlay={showToolTip("Get URL")}>
              <Button className="px-2 mx-2 py-2" variant="light" onClick={() => copyToClipboard(location.href)}>
                <FiLink size={22} />
              </Button>
            </OverlayTrigger>
          </div>
        </>}
      </div>
    </div>
  )
}


export default GradientHeader

