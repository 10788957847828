import React from "react"
import Logo from "../../../public/icons/icon-256x256.png"
import { isDark } from "color-2-name"

function ExportGradientPDF({ gradient, gradientRef, extractColorNames, rotationDegree}) {
  const numberOfColors = extractColorNames(gradient).length;
  const [color1,color2, color3] = extractColorNames(gradient)

  if(!(color1 && color2)) {
    return null
  }

  let padding
  if(rotationDegree === 0 || rotationDegree === 180){
    padding = "5rem"
  }else{
    padding = "43rem 8rem"
  }

  let direction
  if(rotationDegree === 0){
    direction = 'column-reverse'
  }else if(rotationDegree === 90){
    direction = 'row'
  }else if(rotationDegree === 180){
    direction = 'column'
  }else{
    direction = 'row-reverse'
  }

  return (
    <div ref={gradientRef} style={{position: "absolute",
      zIndex: "-2",
      top: -10000
    }}>
      {numberOfColors > 2 ? <div
        style={{ background: gradient, width: "1350px", height: "820px", display: "flex", justifyContent:"space-between", flexDirection: direction }}>
        <p style={{zIndex: 2, padding:padding, fontSize: 26, color: isDark(color1) ? "#FFFFFF" : "#000000", fontWeight:700, textTransform:"uppercase" }}><span style={{backgroundColor: isDark(color1) ? "#FFFFFF40" : "#00000020" , padding:"20px", borderRadius:"10px"}}>{color1}</span></p>
        <p style={{zIndex: 2, padding:padding, fontSize: 26, color: isDark(color2) ? "#FFFFFF" : "#000000", fontWeight:700,textTransform:"uppercase" }}><span style={{backgroundColor: isDark(color2) ? "#FFFFFF40" : "#00000020" , padding:"20px", borderRadius:"10px"}}>{color2}</span></p>
        <p style={{zIndex: 2, padding:padding, fontSize: 26, color: isDark(color3) ? "#FFFFFF" : "#000000", fontWeight:700,textTransform:"uppercase" }}><span style={{backgroundColor: isDark(color3) ? "#FFFFFF40" : "#00000020" , padding:"20px", borderRadius:"10px"}}>{color3}</span></p>
      </div> : <div
        style={{ background: gradient, width: "1350px", height: "820px", display: "flex", justifyContent:"space-between", flexDirection: direction }}>
        <p style={{ zIndex: 2, padding:padding, fontSize: 26, color: isDark(color1) ? "#FFFFFF" : "#000000", fontWeight:700, textTransform:"uppercase" }}><span style={{backgroundColor: isDark(color1) ? "#FFFFFF40" : "#00000020" , padding:"20px", borderRadius:"10px"}}>{color1}</span></p>
        <p style={{zIndex: 2, padding:padding, fontSize: 26, color: isDark(color2) ? "#FFFFFF" : "#000000", fontWeight:700,textTransform:"uppercase" }}><span style={{backgroundColor: isDark(color2) ? "#FFFFFF40" : "#00000020" , padding:"20px", borderRadius:"10px"}}>{color2}</span></p>
      </div>}
      <div style={{display: "flex", justifyContent:"flex-end", padding:"1rem 6rem"}}>
        <img src={Logo} alt="logo-icon" style={{ width: 100 }} />
      </div>

    </div>
  )
}


export default ExportGradientPDF