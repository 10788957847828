import React from "react"
import { Modal } from "react-bootstrap"

function CopyCssCodeModel({ cssModal, handleCloseCSSModal, currentGradientColors, rotationDegree, copyToClipboard, getGradientCSSCode }) {
  return (
    <div>
      <Modal size="md" aria-labelledby="contained-modal-title-vcenter" centered show={cssModal}
             onHide={handleCloseCSSModal}>
        <Modal.Body>
          <div className="d-flex align-items-center flex-column gap-2">
            <h4 className="text-center">Copy CSS code</h4>
            {currentGradientColors.length > 2 ? <div className="my-2" style={{ fontSize: 16 }}>
              <div>
                <code>background:<span style={{textTransform:"uppercase"}}> {currentGradientColors[0]}</span></code>
              </div>
              <div>
                <code>background: -webkit-linear-gradient({rotationDegree}deg, <span style={{textTransform:"uppercase"}}>{currentGradientColors[0]} 0%, {currentGradientColors[1]} 50%, {currentGradientColors[2]} 100%);</span> </code>
              </div>
              <div>
                <code>background: linear-gradient({rotationDegree}deg ,<span style={{textTransform: "uppercase"}}>{currentGradientColors[0]} 0%, {currentGradientColors[1]} 50%, {currentGradientColors[2]} 100%);</span></code>
              </div>
            </div> : <div className="my-2" style={{ fontSize: 16 }}>
              <div>
                <code>background: <span style={{textTransform:'uppercase'}}>{currentGradientColors[0]}</span></code>
              </div>
              <div>
                <code>background: -webkit-linear-gradient({rotationDegree}deg,<span style={{textTransform:'uppercase'}}> {currentGradientColors[0]}, {currentGradientColors[1]});</span> </code>
              </div>
              <div>
                <code>background: linear-gradient({rotationDegree}deg, <span style={{textTransform:'uppercase'}}>{currentGradientColors[0]}, {currentGradientColors[1]});</span></code>
              </div>
            </div>}
            <button className="btn btn-primary py-2"
                    onClick={() => copyToClipboard(getGradientCSSCode(rotationDegree, currentGradientColors))}>
              Click to Copy
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default CopyCssCodeModel