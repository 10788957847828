import React, {useEffect, useRef, useState} from "react"
import {BsCheckCircleFill} from "react-icons/bs"
import GradientColors from "./GradientColors"
import "../../assets/css/gradient-background.css"
import html2canvas from "html2canvas"
import {navigate} from "@reach/router"
import ExportGradientImage from "./ExportGradientImage"
import ExportGradientPDF from "./ExportGradientPDF"
import CopyCssCodeModel from "./CopyCSSCodeModel"
import GradientHeader from "./GradientHeader"
import {getRandomColor, rgbToHex} from "../../helpers/ColorHelper"

export const getGradientString = (degree, color1, color2, color3) => {
  if(color3){
    return `linear-gradient(${degree}deg,${color1},${color2},${color3})`
  }
  return `linear-gradient(${degree}deg,${color1},${color2})`
}

const GradientBackgrounds = ({ location, gradientString }) => {


  const [gradient, setGradient] = useState("")
  const [showAllGradients, setShowAllGradients] = useState(false)
  const [copied, setCopied] = useState(false)
  const [cssModal, setCssModal] = useState(false)
  const [currentGradientColors, setCurrentGradientColors] = useState([])
  const [rotationDegree, setRotationDegree] = useState(90)
  const [copyMessage, setCopyMessage] = useState("")
  const [showThirdColor, setShowThirdColor] = useState(false)
  const [showRemoveBtn, setShowRemoveBtn] = useState(true)
  const [isImgLoading, setIsImgLoading] = useState(false)
  const [isPdfLoading, setIsPdfLoading] = useState(false)
  const gradientRef = useRef(null)
  const gradientPDFRef = useRef(null)

  const paramURL = location.pathname.slice(28)
  const pathUrl = paramURL.replace(/%20/g, " ")
  let modifiedString = pathUrl.replace(/([0-9A-Fa-f]{6})/g, "#$1")
  const updateURLParams = (params) => {
    navigate(`/tools/gradient-backgrounds/${params}`)
  }

  useEffect(() => {
    if (modifiedString !== "gradient") {
      const [degree,color1,color2,color3] = modifiedString.split('-')
      setGradient(getGradientString(degree,color1,color2,color3))
    } else {
      setShowRemoveBtn(false)
      getRandomGradient()
    }
  }, [])


  useEffect(() => {
    const param = [rotationDegree, ...currentGradientColors.map(color => color.replace('#', ''))].join('-');
    updateURLParams(param);
      if (currentGradientColors.length > 2) {
        setShowRemoveBtn(true)
        setShowThirdColor(true)
      } else {
        setShowThirdColor(false)
        setShowRemoveBtn(false)
      }

    window.addEventListener("keydown", handleKeyPress)

    return () => {
      window.removeEventListener("keydown", handleKeyPress)
    }

  }, [currentGradientColors, rotationDegree])

  const copyToClipboard = (text) => {
    const tempTextArea = document.createElement("textarea")
    tempTextArea.value = text
    document.body.appendChild(tempTextArea)
    tempTextArea.select()
    document.execCommand("copy")
    document.body.removeChild(tempTextArea)

    if (location.href === text) {
      setCopyMessage("Gradient URL copied to the clipboard.")
    } else {
      setCopyMessage("Gradient CSS code copied to the clipboard.")
    }

    setCopied(true)
    setTimeout(() => setCopied(false), 2000)

    handleCloseCSSModal()
  }

  const getRandomGradient = () => {
    const color1 = rgbToHex(getRandomColor())
    const color2 = rgbToHex(getRandomColor())

    if(currentGradientColors.length === 3){
      const color3 = rgbToHex(getRandomColor())
      setCurrentGradientColors([color1, color2, color3])
      setGradient(getGradientString(rotationDegree, color1, color2, color3))
    }else{
      setCurrentGradientColors([color1, color2])
      setGradient(getGradientString(rotationDegree, color1, color2))
    }
  }

  const extractColorNames = (gradient) => {
    if (gradient) {
      const colorMatches = gradient.match(/#[0-9a-fA-F]{6}/g)
      return colorMatches && colorMatches.length >= 2 ? colorMatches : []
    }
    return []
  }

  const handleShowAllGradients = () => {
    setShowAllGradients(true)
  }

  const handleHideAllGradients = () => {
    setShowAllGradients(false)
  }

  const handleRotate = (degrees) => {
    const newDegree = degrees < 360 ? degrees : 0
    setRotationDegree(newDegree)
    setGradient(getGradientString(newDegree, ...currentGradientColors))
  }

  const handleOpenCSSModal = () => {
    setCssModal(true)
  }

  const handleCloseCSSModal = () => {
    setCssModal(false)
  }

  const getGradientCSSCode = (rotation, colors) => {
    const [color1, color2, color3] = colors.map(color => color.toUpperCase());
    if (colors.length === 2) {
      return (
        `background: ${color1};\n` +
        `background: -webkit-linear-gradient(${rotation}deg, ${color1}, ${color2});\n` +
        `background: linear-gradient(${rotation}deg, ${color1}, ${color2});`
      )
    } else {
      return (
        `background: ${color1};\n` +
        `background: -webkit-linear-gradient(${rotation}deg, ${color1} 0%, ${color2} 50%, ${color3} 100%);\n` +
        `background: linear-gradient(${rotation}deg, ${color1} 0%, ${color2} 50%, ${color3} 100%);`
      )
    }
  }

  const handleKeyPress = (e) => {
    if (e.code === "Space" || e.code === "Enter") {
      e.preventDefault()
      return getRandomGradient()
    }
  }


  const handleDownloadGradient = () => {
    setIsImgLoading(true)
    const imageWidth = 1600
    html2canvas(gradientRef.current, {
      scale: imageWidth / gradientRef.current.offsetWidth
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png")

      const downloadLink = document.createElement("a")
      downloadLink.href = imgData
      downloadLink.download = "image.png"
      downloadLink.click()
      setIsImgLoading(false)
    })
  }

  useEffect(() => {
    const gradientColors = extractColorNames(gradient)
    setCurrentGradientColors(gradientColors)
  }, [gradient])

  function handleChooseGradient(gradient) {
    setGradient(gradient)
    handleHideAllGradients()
  }

  const handleExportPdf = () => {
    setIsPdfLoading(true)
    const content = gradientPDFRef.current

    if (!content) {
      return
    }

    const pdfWidth = 297
    const pdfHeight = 210

    html2canvas(content).then(async canvas => {
      const imgData = canvas.toDataURL("image/png")
      const pdf = await import('jspdf').then(jspdf => {
        // return new jspdf.jsPDF("l", "px", "a4")
        return new jspdf.jsPDF({
          orientation: "landscape",
          unit: "mm",
          format: [pdfWidth, pdfHeight]
        })
      })

      // const pdf = new jsPDF({
      //   orientation: "landscape",
      //   unit: "mm",
      //   format: [pdfWidth, pdfHeight]
      // })

      const imgProps = pdf.getImageProperties(imgData)
      const pdfHeightAdjusted = (imgProps.height * pdfWidth) / imgProps.width

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeightAdjusted)
      pdf.save("gradient.pdf")
      setIsPdfLoading(false)
    })
  }

  function openFullscreen() {
    let elem = document.getElementById("gradients")
    if (elem.requestFullscreen) {
      elem.requestFullscreen()
    }
  }


  return (
    <>
      <div>
        <GradientHeader rotationDegree={rotationDegree} gradient={gradient} location={location}
                        handleDownloadGradient={handleDownloadGradient}
                        copyToClipboard={copyToClipboard} handleExportPdf={handleExportPdf} handleRotate={handleRotate}
                        extractColorNames={extractColorNames}
                        handleHideAllGradients={handleHideAllGradients}
                        handleOpenCSSModal={handleOpenCSSModal} handleShowAllGradients={handleShowAllGradients}
                        setShowThirdColor={setShowThirdColor} showThirdColor={showThirdColor}
                        showAllGradients={showAllGradients} currentGradientColors={currentGradientColors}
                        setCurrentGradientColors={setCurrentGradientColors}
                        setGradient={setGradient} getRandomColor={getRandomColor} showRemoveBtn={showRemoveBtn}
                        setShowRemoveBtn={setShowRemoveBtn} openFullScreen={openFullscreen} getRandomGradient={getRandomGradient}
         isPdfLoading={isPdfLoading} isImgLoading={isImgLoading} />

        {!showAllGradients && (
          <div id="gradients"
               style={{ background: gradient, width: "100%", height: "42rem", position: "relative" }}>
          </div>
        )}
        {showAllGradients &&
        <GradientColors handleChooseGradient={handleChooseGradient} rotationDegree={rotationDegree} />}
      </div>

      {copied && (
        <p className="text-white bg-black px-5 py-2"
           style={{ position: "fixed", bottom: "6%", left: "40%", borderRadius: 100, fontSize: 16 }}>
          <BsCheckCircleFill size={20} className="mx-2" /> {copyMessage}
        </p>
      )}

      <CopyCssCodeModel copyToClipboard={copyToClipboard} cssModal={cssModal} getGradientCSSCode={getGradientCSSCode}
                        currentGradientColors={currentGradientColors} handleCloseCSSModal={handleCloseCSSModal}
                        rotationDegree={rotationDegree} />
      <ExportGradientImage gradient={gradient} gradientRef={gradientRef} extractColorNames={extractColorNames}
                           rotationDegree={rotationDegree} />
      <ExportGradientPDF gradient={gradient} gradientRef={gradientPDFRef} extractColorNames={extractColorNames}
                         rotationDegree={rotationDegree} />
    </>
  )
}

export default GradientBackgrounds
