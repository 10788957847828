import React  from "react"
import Layout from "../../../components/_App/layout"
import Seo from "../../../components/_App/seo"
import Navbar from "../../../components/_App/Navbar"
import Breadcrumbs from "../../../components/Common/Breadcrumbs"
import GradientBackgrounds from "../../../components/GradientBackgrounds/GradientBackgrounds"
import Partner from "../../../components/Common/Partner"
import MilestonesAchievedSoFar from "../../../components/Common/ClutchTestimonials"
import Footer from "../../../components/_App/Footer"
import { Location } from "@reach/router"
import GradientBackgroundContent from "../../../components/GradientBackgrounds/GradientBackgroundContent"

import "../../../assets/css/tools.css"


const parent = [{
  name: "Tools",
  link: "tools"
}]

function GradientBG({params}) {

  return (
    <Location>
      {
        ({location}) => {
          return (
            <Layout>
             <div className="single-tool gradient-backgrounds">
               <Seo title="Free Online Gradient Generator Tool" />

              <Navbar />
              <div className="contact-area pt-80xx">
                <div className="container pt-5 pb-3 text-small">
                  <div className="section-title">
                    <h1>Gradient Background Generator</h1>
                    {/* <div className="bar" /> */}
                  </div>
                  <div className="d-none">
                  <Breadcrumbs pageTitle="Gradient Background Generator" parent={parent} />
                  </div>
                </div>
              </div>
              
                <GradientBackgrounds location={location} gradientString={params.gradient}/>
                {/*<PageBanner pageTitle="Color Pallets" parent={parent}/>*/}
                <GradientBackgroundContent/>
             

              <Partner />
              {/*<FunFactsArea/>*/}
              <MilestonesAchievedSoFar />
              <Footer />
              </div> 
            </Layout>
          )
        }
      }
    </Location>
  )
}


export default GradientBG