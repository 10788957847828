import React, { useEffect, useState } from "react"
import { getRandomColor, rgbToHex } from "../../helpers/ColorHelper"
import { getGradientString } from "./GradientBackgrounds"

function GradientColors({handleChooseGradient, rotationDegree}) {

  const [gradients, setGradients] = useState([])

  useEffect(() => {
    generateGradients()
  }, [])

  const generateGradients = () => {
    const newGradients = []
    for (let i = 0; i < 200; i++) {
      const color1 = rgbToHex(getRandomColor())
      const color2 = rgbToHex(getRandomColor())
      const gradient = getGradientString(rotationDegree,color1,color2)
      newGradients.push(gradient)
    }
    setGradients(newGradients)
  }

  return (
    <div
      className="gradient-container row px-5 py-2" style={{width: "90%", margin:"0 auto"}}
    >
      {gradients.map((gradient, index) => (
        <div className="gradient-card col-md-3 p-2" key={index}>
          <button
            className="gradient-preview px-2"
            style={{
              background: gradient,
              width: "100%",
              padding: "10rem",
              border: "none",
              borderRadius: 8
            }}
            onClick={() => handleChooseGradient(gradient)}
          >
          </button>
        </div>
      ))}
    </div>
  )
}

export default GradientColors