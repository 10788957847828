import { OverlayTrigger } from "react-bootstrap"
import { RxCross2 } from "react-icons/rx"
import React from "react"

function ColorContainer({
                          colorValue,
                          totalColors,
                          index,
                          hovered,
                          handleRemoveColor,
                          handleColorChange,
                          removeBtnStyle,
                          showToolTip,
                          showRemoveBtn
                        }) {

  const isDesktop = window.innerWidth > 992

  let mobileStyle
  if(!isDesktop){
    if(showRemoveBtn){
      mobileStyle = 'remove-btn-show'
    }else{
      mobileStyle = 'remove-btn-hide'
    }
  }

  return (
    <div className="p-0">
      <div
        className={`container-color${index + 1} d-flex align-items-center`}
        onMouseEnter={() => hovered(index)}
        onMouseLeave={() => hovered(null)}
        style={{ cursor: "pointer"}}
        
      >
        <label for={'color-input-'+index} className="d-flex">
          <input
            id={'color-input-'+index}
            style={{ cursor: "pointer", border: "none", width: 20, height: 20, display: 'inline-block' }}
            type="color"
            value={colorValue}
            onChange={(e) => handleColorChange(e, index)}
          />

          <button
            className="btn px-2 py-0"
            style={{ pointerEvents: "none" }}
          >
            {colorValue}
          </button>
        
        {totalColors === 3 && 
          <OverlayTrigger placement="bottom" overlay={showToolTip("Remove color")}>
            <button
              className={isDesktop ? removeBtnStyle : mobileStyle}
              onClick={() => handleRemoveColor(index)}
            >
              <RxCross2 size={20} />
            </button>
          </OverlayTrigger>
        }
        </label>
      </div>
    </div>
  )
}

export default ColorContainer